import { device } from "src/styles/breakpoints"
import * as newColors from 'src/styles/newColors'
import styled from "styled-components"

export const ContentWrapper = styled.section`
  background-color: ${newColors.primary[500]};
  padding: 64px 0; 

  @media ${device.desktopXXL}{
    padding: 96px 0;
  }

  .main_container{
    &__titles{
      margin-bottom: 64px;

      @media ${device.tablet} {
        width: 80%;
        margin: 0 auto 64px auto; 
      }

      @media ${device.desktopXXL}{
        width: 70%;
      }

      &--clary{
        background-color: ${newColors.orange.clay};
        padding: 8.5px 0;
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;
        border-radius: 9px;

        @media ${device.tablet}{
          width: 72%;
          padding: 20px 26px;
          border-radius: 21.5px;
          margin-right: auto;
        }

        @media ${device.desktopLG}{
          padding: 26.5px;
        }

        @media ${device.desktopXXL}{
          width: 77%;
        }

        &__icon{
          height: 100%;
          width: 24px;
          margin-right: 16px;

          @media ${device.tablet}{
            width: 40px;
            height: 40px;
            margin-right: 26px;
          }

          @media ${device.desktopLG} {
            width: 44px;
            height: 44px;
          }

          @media ${device.desktopXXL} {
            width: 50px;
            height: 50px;
          }
        }
        &__text{
          max-width: 85%;
          color: white;
          font-family: "Citrina", Helvetica, sans-serif;
          font-weight: 500;
          font-size: 19px;
          line-height: 19.2px;
          margin: 0;

          @media ${device.tablet}{
            font-size: 28px;
            line-height: 33.6px;
          }
          
          @media ${device.desktopLG} {
            font-size: 40px;
            line-height: 44px;
          }

          @media ${device.desktopXXL} {
            font-size: 48px;
            line-height: 52.8px;
          }
        }
      }
      
      &--light{
        background-color: ${newColors.orange.light};
        padding: 15px;
        display: flex;
        align-content: center;
        justify-content: space-between;
        align-items: center;
        border-radius: 15px;

        @media ${device.tablet}{
          width: 72%;
          padding: 16.5px 26.5px;
          border-radius: 21.5px;
          margin-left: auto;
        }

        @media ${device.desktopLG}{
          padding: 26.5px;
          width: 75%;
        }

        @media ${device.desktopXXL}{
          width: 72%;
        }

        &__icon{
          height: 100%;
          width: 24px;

          @media ${device.tablet}{
            width: 40px;
            height: 40px;
          }

          @media ${device.desktopLG} {
            width: 44px;
            height: 44px;
          }

          @media ${device.desktopXXL} {
            width: 50px;
            height: 50px;
          }
        }
        &__text{
          max-width: 75%;
          font-family: "Citrina", Helvetica, sans-serif;
          font-weight: 500;
          font-size: 19px;
          line-height: 19.2px;
          margin: 0;
          color: ${newColors.orange.clay};
          
          @media ${device.tablet}{
            font-size: 24px;
            line-height: 28.8px;
            max-width: 80%;
          }

          @media ${device.desktopLG} {
            font-size: 40px;
            line-height: 44px;
            max-width: 90%;
          }
        }
      }
    }
    &__qualities_list{
      margin: 0;

      @media ${device.tablet}{
        display: flex;
        display: flex;
        justify-content: space-around;
      }

      &__item{

        @media ${device.tablet}{
        }
        &__icon_wrapper{
          &__icon{
            height: 25px;
            width: 25px;

            @media ${device.tablet}{
              width: 30px;
              height: 30px;
            }

            @media ${device.desktopLG} {
              width: 35px;
              height: 35px;
            }
          }
        }
      }
    }
  }
`
