import React from 'react'
import IconsSwitch from 'src/components/IconsSwitch/_index'
import { IconsSwitchProps } from 'src/components/IconsSwitch/types'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'
import * as S from './styles'

export type OrangeCardProps = {
  title: string;
  description: string;
  downloadOrEmail: string;
  icon: IconsSwitchProps;
  dataLayerParams?: IDataLayerParams;
  redirectUrl?: string;
}

const OrangeCard = ({ title, description, downloadOrEmail, icon, dataLayerParams, redirectUrl }: OrangeCardProps) => {
  const [ sendDatalayerEvent ] = useDataLayer()
  return (
    <S.Card
      className='card'
    >
      <div>
        <div className='card__icon'>
          <IconsSwitch
            icon={icon.icon}
            size='MD'
            color='white'
            lib={icon.lib}
            className={`w-100 h-100 ${icon.className ?? ''}`}
            customPath={icon.customPath}
          />
        </div>
        <h3
          className='card__title'
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <p
          className='card__description'
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </div>
      { redirectUrl
        ? (
          <span
            className='card__links'
          >
            <u>
              <a
                href={redirectUrl}
                className='text-white'
                onClick={() => {
                  dataLayerParams && sendDatalayerEvent(dataLayerParams)
                }}
                dangerouslySetInnerHTML={{ __html: downloadOrEmail }}
              />
            </u>
          </span>
        )
        : (
          <span
            className='card__links'
            dangerouslySetInnerHTML={{ __html: downloadOrEmail }}
          />
        )
      }

    </S.Card>
  )
}

export default OrangeCard
